import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FAFAFA',
      neutralLight3: '#EAECEF',
      neutralLight2: '#D1D1D1',
      neutralDark4: '#5A5A5A',
      neutralDark3: '#434343',
      neutralDark2: '#222222',
      neutralDark1: '#000000',
      primaryDark: '#1D1D1D',
      primaryLight: '#F9B642',
      dangerLight: '#F23030',
    },
  },
  fontFamily: {
    heading: "'futura-pt', sans-serif",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
